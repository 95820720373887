import Vue from 'vue'
import axios from 'axios';
import router from '../router/router'
const instance = axios.create({
  baseURL: '/api', // 设置你的API基本URL
  timeout: 20000, // 设置请求超时时间
});

instance.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么
    const data = response;
    console.log("data:",data);
    // 在这里检查响应数据，例如，如果 data.code === 'SOME_ERROR_CODE'，则显示特定错误消息
    if (response.data.msg === '请重新登录...') {
      // 使用 Vue.prototype.$message.error 显示特定错误消息
      // 请确保 Vue.prototype.$message 正确引入和配置
      Vue.prototype.$message.error('请重新登录...');
      router.push('/');
    }

    return response;
  },
  (error) => {
    // 对响应错误做些什么
    if (error.response && error.response.status === 500) {
      Vue.prototype.$message.error('服务器错误，请稍后再试');
    } else {
      Vue.prototype.$message.error('网络错误，请检查您的连接');
    }
    return Promise.reject(error);
  }
);


export default instance;
