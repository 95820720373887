<template>
  <div>
    <h2>自动爬取记录</h2>

    <div style="padding-bottom: 30px;">
      <el-select v-model="accountId" placeholder="请选择用户" clearable style="float: left;width: 30%;margin-right:10px ;" @change="loadUserData">
        <el-option v-for="(item, index) in accountIdOptions" :key="index"
          :label="item.userName + (userData == undefined ? '' : userData)" :value="item.id"
          :disabled="item.disabled"></el-option></el-select>
      <el-input v-model="name" placeholder="请输入搜索名称" style="float: left;width: auto;margin-right:10px ;"></el-input>
      <el-button type="primary" style="float: left;" @click="searchByname">搜索</el-button>
      <el-button type="primary" style="float: left;" @click="resetSearch">重置</el-button>
      <el-button type="primary" style="float: left;" @click="batchSave">批量保存</el-button>
    </div>


    <el-table :data="dataList" border size="mini" v-loading="loading" @selection-change="handleSelectionChange" style="margin-top: 30px;">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="类型" prop="type"></el-table-column>

      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="评分" prop="rateNum"></el-table-column>
      <el-table-column label="资源描述" show-overflow-tooltip="true" prop="description"></el-table-column>
      <el-table-column label="关键字" prop="keyword"></el-table-column>
      <el-table-column label="创建时间" prop="createTime">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="showAddConfirm(scope.row.id)">一键保存</el-button>
          <el-button type="danger" @click="showDeleteConfirm(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center">
      <el-pagination :pager-count="total" layout="prev, pager, next, jumper, ->, total" @current-change="current_change">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from './../js/axios';

export default {
  name: 'AutoPac',
  computed: {

  },
  created() {
    this.getAccountInfo()
    this.getList()
  },
  data() {
    return {
      name: '',
      accountId: null,
      userName: '',
      totalSize: 0,
      usedSize: 0,
      loading: false,
      total: 0,//总条数
      pagesize: 10,//指定展示多少条
      currentPage: 1,//当前页码
      dataList: [],
      accountIdOptions: [],
      userData: null,
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
    }
  },
  methods: {
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    searchByname() {
      this.getList();
    },
    resetSearch() {
      this.name = '',
        this.getList();
    },
    current_change(currentPage) {
      this.currentPage = currentPage;
      this.getList();
      this.getAccountInfo();
    },
    getList() {
      let self = this;
      this.loading = true;
      axios
        .get('/ali/getAutoPacList', {
          params: {
            pagesize: this.pagesize,
            pagenumber: this.currentPage,
            name: this.name
          },
        })
        .then((response) => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          console.log(response.data);
          self.dataList = response.data.content;
          self.total = response.data.totalElements;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // 确保不管成功还是失败，都能关闭加载状态
        });
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const date = new Date(dateTime);
      // 这里可以使用日期格式化库，例如 'date-fns' 或 'moment.js' 来进行日期格式化
      // 这里是一个基本的示例：
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return formattedDate;
    },
    showDeleteConfirm(id) {
      this.$confirm('确认删除这条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击确定按钮后的操作，通常是调用删除接口
        axios.delete(`/ali/deleteAutoRec/${id}`)
          .then(response => {
            if (response.data.code === 0) {
              // 删除成功，刷新表格或执行其他操作
              this.getList(); // 重新加载数据
              this.$message.success('删除成功');
            } else {
              this.$message.error('删除失败');
            }
          })
          .catch(error => {
            console.error('删除失败', error);
            this.$message.error('删除失败');
          });
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },
    getAccountInfo() {
      let self = this;
      axios.get('/ali/getAccountList')
        .then(response => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          self.accountIdOptions = response.data;

        })
        .catch(error => {
          // 登录失败处理，可以显示错误消息或执行其他操作
          console.error('登录失败', error);
          this.$message.error('登陆失败')
        });
    },
    loadUserData() {
      axios.get(`/ali/getFolderList/${this.accountId}`)
        .then(response => {
          this.userData = response.data; // 将用户数据存储到 userData 属性
        })

    },
    showAddConfirm(id) {
      if (this.accountId == undefined || this.accountId == '' || this.accountId == null) {
        this.$message.error('请先选择用户');
        return
      }
      this.$confirm('确认保存到网盘吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post(`/ali/saveToDrive/${this.accountId}/${id}`)
          .then(response => {
            if (response.data.code === 0) {
              // 保存成功，刷新表格或执行其他操作
              this.getList(); // 重新加载数据
              this.$message.success('保存成功');
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch(error => {
            console.error('保存失败', error);
            this.$message.error('保存失败');
          });
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },
    batchSave() {
      let id = this.ids.join(',')
      this.$confirm('确认保存到网盘吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post(`/ali/saveToDrive/${this.accountId}/${id}`)
          .then(response => {
            if (response.data.code === 0) {
              // 保存成功，刷新表格或执行其他操作
              this.getList(); // 重新加载数据
              this.$message.success('保存成功');
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch(error => {
            console.error('保存失败', error);
            this.$message.error('保存失败');
          });
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },
  }
};
</script>
