<template>
  <div class="login-container">
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="formData.username" placeholder="请输入用户名" clearable class="input-field">
        </el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="formData.password" placeholder="请输入密码" clearable show-password class="input-field">
        </el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm" class="submit-button">登录</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>
<script>
import axios from './../js/axios';
export default {
  name:'LoginView',
  components: {},
  props: [],
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // 构建登录请求的数据
    // 发送登录请求
    axios.post('/dologin', this.formData)
      .then(response => {
        // 登录成功处理，可以跳转到其他页面或执行其他操作
        console.log(response.data)
        if(response.data.code == 0 ){
          this.$message.success('登陆成功')
          let key = response.data.data.data.tokenName;
          let value = response.data.data.data.tokenValue
          localStorage.setItem('tokenname', key);
          localStorage.setItem('tokenvalue', value);

          axios.defaults.headers.common[key] = value;

          // 示例：成功后跳转到'/ChooseAccount'页面
          this.$router.push('/ChooseAccount');
        }else{
          this.$message.error('登录失败，请检查用户名或密码是否')
        }
        
      })
      .catch(error => {
        // 登录失败处理，可以显示错误消息或执行其他操作
        console.error('登录失败', error);
        this.$message.error('登陆失败')
      });
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style scoped>
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4; /* 背景颜色 */
}

.input-field {
  width: 300px; /* 设置输入框宽度 */
  margin-bottom: 15px; /* 设置输入框之间的间距 */
}

.submit-button {
  width: 150px; /* 设置提交按钮宽度 */
  background-color: #007BFF; /* 按钮背景颜色 */
  border: none;
}

.submit-button:hover {
  background-color: #0056b3; /* 鼠标悬停时的颜色 */
}

</style>
