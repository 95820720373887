<template>
  <div id="app">
    <!-- 你的应用的顶部导航栏或其他全局元素可以放在这里 -->
    
    <router-view></router-view>
    
    <!-- 你的应用的底部导航栏或其他全局元素可以放在这里 -->
  </div>
</template>

<script>
export default {
  name: 'App',
  // 可以在这里定义全局数据、生命周期钩子等
}
</script>

<style>
/* 可以在这里定义全局样式 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
