import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/router' // 根据实际文件名和路径进行调整
import axiosInstance from './js/axios';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.config.errorHandler = (err, vm, info) => {
  // 在控制台打印错误信息
  console.error(`Error: ${err.toString()}\nInfo: ${info}`)
  
  // 在这里你可以添加自定义的错误提示，也可以将错误信息发送到服务器或记录到日志等操作
  // 例如，使用 Element UI 的 Message 组件显示一个错误提示
  Vue.prototype.$message.error('发生了一个错误，请稍后重试')

  // 如果你不希望在控制台看到这个错误，可以取消下一行的注释
  // throw err
}


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
Vue.prototype.$axios = axiosInstance;