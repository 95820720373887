import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/components/LoginView.vue' // 根据你的项目结构进行路径调整
import ChooseAccount from '@/components/ChooseAccount.vue'
import toListView from '@/components/ListView.vue'
import UserList from '@/components/UserList.vue'
import AutoPac from '@/components/AutoPac.vue'
import BookList from '@/components/BookList.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  // 其他路由配置...
  {
    path: '/ChooseAccount',
    name: 'ChooseAccount',
    component: ChooseAccount
  },
  {
    path: '/toListView:params',
    name: 'toListView',
    component: toListView,
    props:true
  },
  {
    path: '/UserList',
    name: 'UserList',
    component: UserList
  }
  ,
  {
    path: '/AutoPac',
    name: 'AutoPac',
    component: AutoPac
  },
  {
    path: '/BookList',
    name: 'BookList',
    component: BookList
  }
]

const router = new VueRouter({
  routes
})

export default router
