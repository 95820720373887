<template>
  <div>
    <h2>保存记录</h2>
    <div>
      用户：{{ userName }}
    </div>
    <div>
      网盘使用情况 已用 {{usedSize}} TB，总共 {{totalSize}} TB<el-progress
      :percentage="usedSize < totalSize ? ((usedSize / totalSize).toFixed(1)) * 100 : 100"
      :text-inside="true" :stroke-width="24" 
    >
      {{ usedSize < totalSize ? (usedSize / totalSize) * 100 : 100 }}%
    </el-progress>
    </div>
    <el-table :data="dataList" border size="mini"
      v-loading="loading">

      <el-table-column label="目录" prop="floderName">

      </el-table-column>
      <el-table-column label="文件名称" prop="fileName">

      </el-table-column>
      <el-table-column label="创建时间" prop="createTime">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center">
      <el-pagination  :pager-count="total"  layout="prev, pager, next, jumper, ->, total"  @current-change="current_change">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from './../js/axios';

export default {
  name: 'toListView',
  props: ['data'], // 接收传递的接口数据
  computed: {

  },
  created() {
    console.log(this.$route.params.accountId);
    this.getAccountInfo()
    this.getList()
  },
  data() {
    return {
      userName:'',
      totalSize:0,
      usedSize:0,
      loading: false,
      total: 0,//总条数
      pagesize: 10,//指定展示多少条
      currentPage: 1,//当前页码
      dataList: []
    }
  },
  methods: {
    current_change(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getAccountInfo() {
      let self = this;
      this.loading = true;
      axios
        .get('/ali/getAccountInfo/' + this.$route.params.accountId)
        .then((response) => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          console.log(response.data);
          self.userName = response.data.userName;
          Math.round()
          self.totalSize = (response.data.totalSize/(1024*1024*1024*1024)).toFixed(2);
          self.usedSize = (response.data.usedSize/(1024*1024*1024*1024)).toFixed(2);
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // 确保不管成功还是失败，都能关闭加载状态
        });
    },
    getList() {
      let self = this;
      this.loading = true;
      axios
        .get('/ali/getAccountShareList/' + this.$route.params.accountId, {
          params: {
            pagesize: this.pagesize,
            pagenumber: this.currentPage,
          },
        })
        .then((response) => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          console.log(response.data);
          self.dataList = response.data.content;
          self.total = response.data.totalElements;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // 确保不管成功还是失败，都能关闭加载状态
        });
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const date = new Date(dateTime);

      // 这里可以使用日期格式化库，例如 'date-fns' 或 'moment.js' 来进行日期格式化
      // 这里是一个基本的示例：
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return formattedDate;
    },
  }
};
</script>
