<template>
  <div>
    <h2>用户列表</h2>
    <div style="float: left;margin-bottom: 10px;">
      <el-button @click="openAdd" type="primary">新增用户</el-button>
      <el-button @click="conformFlush" type="primary">刷新所有token</el-button>
    </div>
    <el-table :data="dataList" border  size="mini" v-loading="loading"
      style="margin-bottom: 10px;">

      <el-table-column label="用户" prop="userName">

      </el-table-column>

      <el-table-column label="token" prop="refreshToken">

      </el-table-column>
      <el-table-column label="已用容量" prop="usedSize">
        <template slot-scope="scope">
          {{ (scope.row.usedSize / 1024 / 1024 / 1024 / 1024).toFixed(2) }}TB
        </template>

      </el-table-column>
      <el-table-column label="总容量" prop="totalSize">
        <template slot-scope="scope">
          {{ (scope.row.totalSize / 1024 / 1024 / 1024 / 1024).toFixed(2) }}TB
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateTime">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column label="备注" prop="remark">

      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="danger" @click="showDeleteConfirm(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center">
      <el-pagination :pager-count="total" layout="prev, pager, next, jumper, ->, total" @current-change="current_change">
      </el-pagination>
    </div>

    <div>
      <el-dialog :visible.sync="createFlag" @open="onOpen" @close="onClose" title="新增用户">
        <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
          <el-form-item label="token" prop="refreshToken">
            <el-input v-model="formData.refreshToken" placeholder="请输入token" clearable :style="{ width: '100%' }">
            </el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="formData.remark" placeholder="请输入单行备注" clearable :style="{ width: '100%' }">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="handelConfirm">确定</el-button>
          <el-button @click="close">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import axios from './../js/axios';

export default {
  name: 'UserList',
  computed: {

  },
  created() {
    this.getList()
  },
  data() {
    return {
      dataList: [],
      loading: false,
      formData: {
        refreshToken: undefined,
        remark: undefined,
      },
      rules: {
        refreshToken: [{
          required: true,
          message: '请输入token',
          trigger: 'blur'
        }],
        remark: [{
          required: false,
          message: '请输入备注',
          trigger: 'blur'
        }],
      },
      createFlag: false
    }
  },
  methods: {
    getList() {
      let self = this;
      this.loading = true;
      axios
        .get('/ali/getUserList')
        .then((response) => {
          console.log(response.data);
          self.dataList = response.data;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // 确保不管成功还是失败，都能关闭加载状态
        });
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const date = new Date(dateTime);

      // 这里可以使用日期格式化库，例如 'date-fns' 或 'moment.js' 来进行日期格式化
      // 这里是一个基本的示例：
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return formattedDate;
    },
    onOpen() { },
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.createFlag = false
    },
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        console.log(valid)
        if (!valid) return
        axios.post('/ali/userSave', this.formData)
          .then(response => {
            console.log(response.data)
            if (response.data.code == 0) {
              this.$message.success('新增成功')
            } else {
              this.$message.error('新增失败')
            }

          })
          .catch(error => {
            console.error('新增失败', error);
            this.$message.error('新增失败')
          }).finally(() => {
            this.getList()
            this.onClose()
            this.close()
          })
      })
    },
    openAdd() {
      this.createFlag = true
    },
    deleteItem(itemId) {
      // 向后端发送删除请求，itemId是要删除的数据的唯一标识
      axios.delete(`/ali/delete/${itemId}`)
        .then(response => {
          if (response.data.code === 0) {
            // 删除成功，刷新表格或执行其他操作
            this.getList(); // 重新加载数据
            this.$message.success('删除成功');
          } else {
            this.$message.error('删除失败');
          }
        })
        .catch(error => {
          console.error('删除失败', error);
          this.$message.error('删除失败');
        });
    },
    current_change() {

    },
    showDeleteConfirm(id) {
      this.$confirm('确认删除这条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击确定按钮后的操作，通常是调用删除接口
        this.deleteItem(id);
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },
    conformFlush() {
      this.$confirm('确认刷新所有token吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击确定按钮后的操作，通常是调用删除接口
        this.flushToken();
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },
    flushToken() {
      axios.get(`/ali/flush`)
        .then(response => {
          if (response.data.code === 0) {
            // 
            this.getList(); // 重新加载数据
            this.$message.success('刷新成功');
          } else {
            this.$message.error('刷新失败');
          }
        })
        .catch(error => {
          console.error('刷新失败', error);
          this.$message.error('刷新失败');
        });
    },
    
  }
};
</script>
