<template>
  <div>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
      <el-form-item label="用户" prop="accountId">
        <el-select style="width: 30%" v-model="formData.accountId" placeholder="请选择用户" clearable :style="{ width: '60%' }"
          @change="loadUserData">
          <el-option v-for="(item, index) in accountIdOptions" :key="index" :label="item.userName" :value="item.id"
            :disabled="item.disabled"></el-option>
        </el-select>
        <el-button @click="findAll" type="primary">查看历史保存记录</el-button>
        <div v-if="userData">
          <!-- 在这里展示 userData 的相关信息 -->
          <p>用户已有文件夹：{{ userData }}</p>
        </div>
      </el-form-item>
      <el-form-item label="上传" prop="file" required>
        <el-upload :headers="headerObj" ref="file" :file-list="filefileList" :action="fileAction"
          :data="{ accountId: this.formData.accountId }" :before-upload="fileBeforeUpload" :on-success="turnToListView">
          <el-button size="small" type="primary" icon="el-icon-upload">点击上传</el-button>
        </el-upload>
      </el-form-item>

    </el-form>
    <div style=" display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;">

      <el-button @click="findUser" type="primary">查看用户列表</el-button>
      <el-button @click="findAutoData" type="primary">查看爬取信息</el-button>
      <el-button @click="findBookData" type="primary">搜索电子书库</el-button>
    </div>

    <el-table :data="dataList">
      <el-table-column label="用户" prop="accountId">

      </el-table-column>
      <el-table-column label="目录" prop="floderName">

      </el-table-column>
      <el-table-column label="文件名称" prop="fileName">

      </el-table-column>
      <el-table-column label="创建时间" prop="createTime">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createTime) }}
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>
<script>


import axios from './../js/axios';


export default {
  components: {},
  props: [],
  data() {
    return {
      headerObj: {
        satoken: localStorage.getItem("tokenvalue"),
      },
      userData: null,
      dataList: [],
      formData: {
        accountId: undefined,
        file: null,
      },
      rules: {
        accountId: [{
          required: true,
          message: '请选择下拉选择下拉选择',
          trigger: 'change'
        }],
      },
      fileAction: '/api/ali/upload',
      accountIdOptions: [],
      uploadData: {},
      filefileList: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getAccountInfo();

  },
  mounted() {
  },
  methods: {
    getAccountInfo() {
      let self = this;
      axios.get('/ali/getAccountList')
        .then(response => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          self.accountIdOptions = response.data;

        })
        .catch(error => {
          // 登录失败处理，可以显示错误消息或执行其他操作
          console.error('登录失败', error);
          this.$message.error('登陆失败')
        });
    },
    fileBeforeUpload(file) {
      if (this.formData.accountId === undefined || this.formData.accountId === '' || this.formData.accountId == null) {
        this.$message.error('请先选择用户');
        return
      }
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      const data = {
        accountId: this.formData.accountId,
      };

      // 设置 el-upload 的 data 属性，传递额外的参数
      this.uploadData = data;
      return isRightSize
    },
    turnToListView(response) {
      if (response.code == 0) {
        this.$message.success("上传成功");
        const data = response.data.dataList; // 假设接口返回的数据在dataList字段中
        console.log(data);
        this.dataList = data;
      } else {
        this.$message.error("上传失败");
      }
    },
    formatDateTime(dateTime) {
      if (!dateTime) return '';
      const date = new Date(dateTime);
      // 这里可以使用日期格式化库，例如 'date-fns' 或 'moment.js' 来进行日期格式化
      // 这里是一个基本的示例：
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      return formattedDate;
    },
    findAll() {
      if (this.formData.accountId == undefined || this.formData.accountId == '' || this.formData.accountId == null) {
        this.$message.error('请先选择用户');
        return
      }
      let a = this.formData.accountId
      this.$router.push({
        name: 'toListView', // 路由名称，要跳转到的页面的路由名称
        params: { accountId: a }, // 参数名称和值
      });
    },
    loadUserData() {
      axios.get(`/ali/getFolderList/${this.formData.accountId}`)
        .then(response => {
          this.userData = response.data; // 将用户数据存储到 userData 属性
        })

    },
    findUser() {
      this.$router.push({
        name: 'UserList'// 参数名称和值
      });
    },
    findAutoData() {
      this.$router.push({
        name: 'AutoPac'
      });
    },
    findBookData() {
      this.$router.push({
        name: 'BookList'
      });
    },
  },

}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}
</style>
