<template>
  <div>
    <h2>自动爬取记录</h2>

    <div style="padding-bottom: 30px;">
      <el-input v-model="name" placeholder="请输入书名" style="float: left;width: auto;margin-right:10px ;"></el-input>
      <el-button type="primary" style="float: left;" @click="searchByname">搜索</el-button>
      <el-button type="primary" style="float: left;" @click="resetSearch">重置</el-button>
    </div>


    <el-table :data="dataList" border size="mini" v-loading="loading" style="margin-top: 30px;">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="downloadBook(scope.row.name)">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import axios from './../js/axios';

export default {
  name: 'AutoPac',
  computed: {

  },
  created() {
    this.getList()
  },
  data() {
    return {
      name: 'txt',
      accountId: null,
      userName: '',
      totalSize: 0,
      usedSize: 0,
      loading: false,
      total: 0,//总条数
      pagesize: 10,//指定展示多少条
      currentPage: 1,//当前页码
      dataList: [],
      accountIdOptions: [],
      userData: null,
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
    }
  },
  methods: {
    searchByname() {
      this.getList();
    },
    resetSearch() {
      this.name = '',
        this.getList();
    },
    getList() {
      let self = this;
      this.loading = true;
      axios
        .get('/ali/searchBook', {
          params: {
            name: this.name
          }
        })
        .then((response) => {
          // 登录成功处理，可以跳转到其他页面或执行其他操作
          console.log(response.data);
          self.dataList = response.data;
          // self.total = response.data.totalElements;
        })
        .catch((error) => {
          console.error('Error:', error);
        })
        .finally(() => {
          this.loading = false; // 确保不管成功还是失败，都能关闭加载状态
        });
    },
    downloadBook(name) {
      this.$confirm('确认下载这条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 用户点击确定按钮后的操作，通常是调用删除接口
        axios.get(`/ali/getDownLoadUrl?name=` + name)
          .then(response => {
            if (response.data != null) {
              // const a = document.createElement('a');
              // a.style.display = 'none';
              // document.body.appendChild(a);

              // // 设置a标签的href和download属性
              // a.href = response.data.url;
              // // a.download = name; // 设置下载文件的名称

              // // 模拟点击a标签
              // a.click();
              window.open(response.data.url, '_blank', "noopener,noreferrer");
              
              this.$message.success('下载成功');
            } else {
              this.$message.error('下载失败');
            }
          })
          .catch(error => {
            console.error('下载失败', error);
            this.$message.error('下载失败');
          });
      }).catch(() => {
        // 用户点击取消按钮后的操作
      });
    },

  }
};
</script>
